// GET MASTER DATA, DOWNLOAD FROM AWS S3 and INCLUDES FORM INPUT VALIDATION
import { NotificationManager as notify } from 'react-notifications';
import * as countryList from 'country-data';
import * as MasterDataAPI from '../api/master-data-api';
import axios from 'axios';
import _ from 'lodash';

export function checkEmpty(field) {
    return field && /^\s+$/.test(field)
}

export function matchEmailFormat(email) {
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return email && email.match(mailFormat)
}

export function encodeJSON(value, type) {
    if (type === "json") {
        return Buffer(JSON.stringify(value)).toString("hex");
    }
    else {
        return Buffer(value).toString("hex");
    }
}

export function decodeJSON(value, type) {
    if (type === "json") {
        return JSON.parse(Buffer.from(value, "hex").toString())
    }
    else {
        try {
            if (!_.isString(value) && !_.isBuffer(Buffer.from(value, "hex")) && !_.isBuffer(value) && !_.isArrayLikeObject(value)) {
                return "";
            }
            const parsedVal=parseInt(value, 16);
            const buf = Buffer.from(value, "hex").toString();
            return buf;
        } catch (error) {
            return "";
        }

    }
}

export function countryCodes() {
    let countryCodes = countryList.callingCountries.all;
    let codes = countryCodes.map((code, i) => {
        return {
            countryName: code.name,
            code: code.countryCallingCodes && code.countryCallingCodes[0]
        }
    })
    return codes;
}

export function downloadBucketFiles(url) {
    axios.get(url)
        .then(response => {
            console.log("SUCCESS", response);
        }, error => {
            console.log("ERROR", error);
        });
}

export function getCities() {
    return new Promise((resolve, reject) => {

        MasterDataAPI.getCities().then(
            response => {
                let allCities = response.data && response.data.data;
                resolve(allCities);
            },
            error => {
                reject(error);
            }
        )
    })
}

export function getStates() {
    return new Promise((resolve, reject) => {

        MasterDataAPI.getStates().then(
            response => {
                let allStates = response.data && response.data.data;
                resolve(allStates);
            },
            error => {
                reject(error);
            }
        )
    })
}

export function getCountries() {
    return new Promise((resolve, reject) => {

        MasterDataAPI.getCountries().then(
            response => {
                let allCountries = response.data && response.data.data;
                resolve(allCountries);
            },
            error => {
                reject(error);
            }
        )
    })
}

export function getBoards() {
    return new Promise((resolve, reject) => {

        MasterDataAPI.getBoards().then(
            response => {
                let allBoards = response.data && response.data.data;
                resolve(allBoards);
            },
            error => {
                reject(error);
            }
        )
    })
}

export function getInstitutes() {

    return new Promise((resolve, reject) => {
        MasterDataAPI.getInstitutes().then(
            response => {
                let allInstitutes = response.data && response.data.data;
                let other = {
                    location: "Other Institute",
                    id: 0,
                    institituteEmail: "",
                    institituteName: "Other Institute"
                }
                allInstitutes.unshift(other);
                allInstitutes.map(ai => {
                    if (ai.id !== 0) {
                        ai.institituteName = `${ai.institituteName}, ${ai.location}`
                    }
                })
                resolve(allInstitutes);
            },
            error => {
                reject(error);
            }
        )
    })
}

export function getGrades() {
    return new Promise((resolve, reject) => {
        MasterDataAPI.getGrades().then(
            response => {
                let allGrades = response.data && response.data.data;
                resolve(allGrades);
            },
            error => {
                reject(error);
            }
        )
    })
}

export function getCollegeDegrees() {
    return new Promise((resolve, reject) => {
        MasterDataAPI.getCollegeDegrees().then(
            response => {
                let allCollegeDegrees = response.data && response.data.data;
                resolve(allCollegeDegrees);
            },
            error => {
                reject(error);
            }
        )
    })
}

export function getStudents() {
    return new Promise((resolve, reject) => {

        MasterDataAPI.getStudents().then(
            response => {
                let allStudents = response.data && response.data.data;
                resolve(allStudents);
            },
            error => {
                reject(error)
            }
        )
    })
}

export function years(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

export function emptyField() {
    let field = document.getElementsByClassName('emptyfield'), errors = [];
    let fieldsize = field.length;


    for (let i = 0; i < fieldsize; i++) {
        if (field[i].value === "") {
            notify.show("Some field is empty", "error");
        }
    }
}

export function numbersOnly(event) {
    // handle paste
    if (event.type === 'paste') {
        key = event.clipboardData.getData('text/plain');
    } else {
        // handle enter
        var key = event.keyCode || event.which;
        key = String.fromCharCode(key);
    }
    var regex = /[0-9]/;
    if (!regex.test(key)) {
        event.returnValue = false;
        event.preventDefault();
        return false;
    }
    return true;
}

export function checkComma(event) {
    // handle paste
    if (event.type === 'paste') {
        key = event.clipboardData.getData('text/plain');
    } else {
        // handle enter
        var key = event.keyCode || event.which;
        key = String.fromCharCode(key);
    }
    var value = `${event.target.value}${key}`;
    if (value.split(',').length > 2) {
        event.returnValue = false;
        event.preventDefault();
        return false;
    }
    return true;
}

export function decimalsOnly(event) {
    // handle paste
    if (event.type === 'paste') {
        key = event.clipboardData.getData('text/plain');
    } else {
        // handle enter
        var key = event.keyCode || event.which;
        key = String.fromCharCode(key);
    }
    var value = `${event.target.value}${key}`;
    var regex = /^[^.]*[0-9]|\./;
    if (!regex.test(value) || value.split('.').length > 2 || isNaN(value) || parseFloat(value) === 0) {
        event.returnValue = false;
        event.preventDefault();
        return false;
    }
    return true;
}


export function handleAppliationError(error) {
    if (error && error.response && error.response.status < 500) {
        notify.error(error.message, "Application error");
    }
}

export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
};

export function makeCopy(object) {
    return JSON.parse(JSON.stringify(object));
}